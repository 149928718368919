<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비유형" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비관리번호" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="설비명" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="설비 사양"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="equipmentSpecId"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteSpec" />
              <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveSpec" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-spec',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'item',
            field: 'item',
            label: '항목',
            style: 'width:100px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'itemValue',
            field: 'itemValue',
            label: '값',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            label: '단위',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.spec.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.spec.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.spec.delete.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {plantCd: this.param.plantCd, equipmentCd: this.param.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveSpec() {
      let checkItem = ['item']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [항목]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      this.grid.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,
        equipmentCd: this.param.equipmentCd,
        equipmentSpecId: uid(),
        orderNo: '0',
        item: '',
        itemDesc: '',
        itemValue: '',
        itemUnit: '',
      })
    },
    deleteSpec() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>